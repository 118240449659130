export function getSkeleton(granularity) {
    switch (granularity) {
        case 1:
            return "yMd";
        case 2:
            return "yMd";
        case 3:
            return "yM";
        case 5:
            return "y";
        case 6:
            return "";
        default:
            throw `The granularity value ${granularity} is not managed.`;
    }
}
;
